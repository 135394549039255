import * as Klaro from 'klaro';

export default {
    init() {
        const config = {
            elementID: 'cookie-banner',
            storageMethod: 'cookie',
            storageName: 'klaro-cookie',
            mustConsent: false, // Cookie banner is a Modal
            acceptAll: true,
            hideDeclineAll: false,
            translations: {
                de: {
                    privacyPolicyUrl: 'https://www.nukkuaa.com/datenschutz',
                    consentNotice: {
                        description:
                            'Wir nutzen Cookies, um dir bestmögliche Funktionalitäten zu bieten. Mehr Infos dazu findest du hier: {privacyPolicy}',
                        learnMore: 'Einstellungen'
                    },
                    consentModal: {
                        title: 'Cookie Einstellungen',
                        description:
                            'Wir möchten Ihre Daten für folgende Zwecke verwenden dürfen. ',
                        privacyPolicy: {
                            text: 'Dies ist der Text mit einem Link zu Ihrer {privacyPolicy}.',
                            name: 'Datenschutzerklärung (Name)'
                        }
                    },
                    ok: 'Alle akzeptieren',
                    decline: 'Funktionale Cookies',
                    acceptSelected: 'Speichern',
                    save: 'Speichern',
                    close: 'Schließen',
                    acceptAll: 'Alle akzeptieren',
                    purposes: {
                        functional: 'Funktionale Cookies',
                        analytics: 'Statistiken'
                    }
                },
                en: {
                    privacyPolicyUrl:
                        'https://www.nukkuaa.com/en/data-protection-policy',
                    consentNotice: {
                        description:
                            'We use cookies to offer you the best possible functionality. You can find more information here: {privacyPolicy}',
                        learnMore: 'Settings'
                    },
                    consentModal: {
                        title: 'Cookie settings',
                        description:
                            'We use cookies to offer you the best possible functionality. ',
                        privacyPolicy: {
                            text: 'Dies ist der Text mit einem Link zu Ihrer {privacyPolicy}.',
                            name: 'Privacy Policy (Name)'
                        }
                    },
                    ok: 'Accept all',
                    decline: 'Functional cookies',
                    acceptSelected: 'Save selection',
                    save: 'Save',
                    close: 'Close',
                    acceptAll: 'Accept all',
                    purposes: {
                        functional: 'Functional cookies',
                        analytics: 'Statistic'
                    }
                }
            },
            services: [
                {
                    name: 'functional',
                    title: 'Craft CMS',
                    default: true,
                    purposes: ['functional'],
                    cookies: [
                        ['CraftSessionId'],
                        ['*_identity'],
                        ['*_username'],
                        ['CRAFT_CSRF_TOKEN']
                    ],
                    required: true
                },
                {
                    name: 'google-analytics',
                    title: 'Google Analytics',
                    purposes: ['analytics'],
                    cookies: [[/^_ga.*$/i], ['_gid']],
                    required: false
                }
            ]
            // callback: function (consent, service) {
            //     console.log(
            //         'User consent for service ' +
            //             service.name +
            //             ': consent=' +
            //             consent
            //     );
            // }
        };

        // we assign the Klaro module to the window, so that we can access it in JS
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const w = window as any;
        w.klaro = {};
        w.klaro = Klaro;
        w.klaroConfig = config;
        // we set up Klaro with the config
        Klaro.setup(config);
    }
};
