export default {
    init() {
        const accordionSections = document.querySelectorAll(
            '.accordion-section'
        ) as NodeListOf<HTMLElement>;

        if (!accordionSections.length) return;

        accordionSections.forEach(function (accordionSection) {
            const accordionHeads =
                accordionSection.querySelectorAll('.accordion-head');

            if (!accordionHeads) return;

            if (accordionHeads.length) {
                accordionHeads.forEach(function (accordionHead) {
                    accordionHead.addEventListener('click', function (event) {
                        event.stopPropagation();

                        const content =
                            accordionHead.nextElementSibling as HTMLElement;

                        if (!content) return;

                        if (accordionHead.classList.contains('active')) {
                            accordionHead.classList.remove('active');
                            content.setAttribute('style', 'maxHeight:0;');
                        } else {
                            accordionHead.classList.add('active');
                            content.style.maxHeight =
                                content.scrollHeight + 'px';
                        }
                    });
                });
            }
        });
    }
};
