export default {
    init() {
        const blogTeaserSections = document.querySelectorAll(
            '.blog-teaser-section'
        );
        if (!blogTeaserSections) {
            return;
        }

        if (window.screen.height <= 768) {
            return;
        }

        blogTeaserSections.forEach((section) => {
            const cards = section.querySelectorAll(
                '.card-contents'
            ) as NodeListOf<HTMLElement>;
            const heigthCards: number[] = [];

            if (!cards) {
                return;
            }

            cards.forEach((card) => {
                if (card) {
                    heigthCards.push(card.clientHeight);
                }
            });

            cards.forEach((card) => {
                if (card) {
                    card.style.height = Math.max(...heigthCards) + 'px';
                }
            });
        });
    }
};
