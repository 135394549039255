import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

// if you have a component which is not on each page available and you would like to lazy load
// check the DOM for it, then lazy load corresponding react component
// provide Suspense fallback (loading state)
export default {
    init() {
        const login = document.getElementById('react-login');
        const licensesOverview = document.getElementById(
            'react-licenses-overview'
        );

        if (login) {
            const Component = React.lazy(
                () => import('./pages/Login' /* webpackPrefetch: true */)
            );
            const App = (
                <Suspense fallback={null}>
                    <Component />
                </Suspense>
            );
            ReactDOM.render(App, login);
        }

        if (licensesOverview) {
            const Component = React.lazy(
                () =>
                    import(
                        './pages/LicensesOverview' /* webpackPrefetch: true */
                    )
            );
            const App = (
                <Suspense fallback={null}>
                    <Component />
                </Suspense>
            );
            ReactDOM.render(App, licensesOverview);
        }
    }
};
