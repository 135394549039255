export default {
    init() {
        const formContainers = document.querySelectorAll(
            '.form-section, .newsletter-registration-section, .quiz-section, .offerings-section'
        ) as NodeListOf<HTMLElement>;

        if (!formContainers) {
            return;
        }

        formContainers.forEach(function (formContainer: HTMLElement) {
            const forms = formContainer.querySelectorAll(
                '.form-init'
            ) as NodeListOf<HTMLElement>;

            if (!forms.length) {
                return;
            }

            forms.forEach(function (form: HTMLElement) {
                const formId = form.dataset.formId;

                if (!formId) {
                    return;
                }

                const currentForm = document.querySelector('#' + formId);

                if (!currentForm) return;

                const formHandle = currentForm.querySelector(
                    'input[name="handle"]'
                ) as HTMLInputElement;
                if (!formHandle) {
                    return;
                }
                //Find the CSRF token hidden input, so we can replace it
                let csrfInput = currentForm.querySelector(
                    'input[name="CRAFT_CSRF_TOKEN"]'
                ) as HTMLInputElement;

                if (!formHandle || !csrfInput) return;

                // Fetch the new token for the form and replace the CSRF input with our new one
                fetch(
                    '/actions/formie/forms/refresh-tokens?form=' +
                        formHandle.value
                )
                    .then((result) => {
                        return result.json();
                    })
                    .then((result) => {
                        if (!form) return;

                        // Find the JavaScript captcha hidden input, so we can update it
                        if (result.captchas && result.captchas.javascript) {
                            // JavaScript captcha
                            const jsCaptcha = result.captchas.javascript;

                            const formCaptchaInputField = form.querySelector(
                                'input[name="' + jsCaptcha.sessionKey + '"]'
                            ) as HTMLInputElement;

                            formCaptchaInputField.value = jsCaptcha;
                        }

                        csrfInput = result.csrf.input;

                        const formInputField = form.querySelector(
                            'input[name="' + result.csrf.param + '"]'
                        ) as HTMLInputElement;

                        if (!formInputField) return;

                        formInputField.value = result.csrf.token;
                    });

                if (
                    formHandle.value === 'tkForm' ||
                    formHandle.value === 'tkRegistrierung'
                ) {
                    const hiddenField = form.querySelector(
                        'input[name="fields[browserLanguage]"]'
                    ) as HTMLInputElement;

                    if (!hiddenField) {
                        return;
                    }

                    form.addEventListener('onBeforeFormieSubmit', () => {
                        function getBrowserLanguage() {
                            // Check if the browser provides a list of languages
                            if (
                                navigator.languages &&
                                navigator.languages.length > 0
                            ) {
                                return navigator.languages[0];
                            }
                            // Fallback to the primary browser language
                            if (navigator.language) {
                                return navigator.language;
                            }
                            // Default to german if no language is found
                            return 'de';
                        }

                        hiddenField.value = getBrowserLanguage();
                    });
                }
            });
        });
    }
};
