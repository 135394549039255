export default {
    init() {
        const quizSections = document.querySelectorAll(
            '.quiz-section'
        ) as NodeListOf<HTMLElement>;

        if (!quizSections.length) return;

        quizSections.forEach(function (quizSection) {
            const quizSlides = quizSection.querySelectorAll(
                '.quiz-slide'
            ) as NodeListOf<HTMLElement>;
            const quizSlideContentDivs = quizSection.querySelectorAll(
                '.quiz-slide-content'
            ) as NodeListOf<HTMLElement>;
            const quizSlideContentDivsMobile = quizSection.querySelectorAll(
                '.quiz-slide-content-mobile'
            ) as NodeListOf<HTMLElement>;
            const navigationButtons = quizSection.querySelector(
                '.navigation-buttons'
            );
            const repeatButtonDiv =
                quizSection.querySelector('.repeat-button-div');
            const repeatButton =
                repeatButtonDiv?.querySelector('.repeat-button');
            const lastQuizSlide = quizSection.querySelector('.last-quiz-slide');

            const quizContentDiv = quizSection.querySelector(
                '.quiz-content-div'
            ) as HTMLElement;

            if (
                !quizSlides ||
                !navigationButtons ||
                !lastQuizSlide ||
                !repeatButton
            )
                return;

            const nextButton = navigationButtons.querySelector('.next-button');
            const prevButton = navigationButtons.querySelector('.prev-button');

            if (!nextButton || !prevButton) return;

            let overallScore = 0 as number;
            let answerScore = 0;

            // show first slide
            const firstQuizSlide = quizSlides[0];
            if (firstQuizSlide) {
                firstQuizSlide.classList.add('active');
            }

            // functions
            function getActiveSlide() {
                const activeQuizSlide =
                    quizSection.querySelector('.quiz-slide.active');
                return activeQuizSlide;
            }

            function getPreviousSlide() {
                const activeQuizSlide = getActiveSlide();
                if (!activeQuizSlide) return;
                const prevQuizSlide =
                    activeQuizSlide.previousElementSibling as HTMLElement;
                return prevQuizSlide;
            }

            function getNextSlide() {
                const activeQuizSlide = getActiveSlide();
                if (!activeQuizSlide) return;
                const nextQuizSlide =
                    activeQuizSlide.nextElementSibling as HTMLElement;
                return nextQuizSlide;
            }

            nextButton.addEventListener('click', function () {
                const activeQuizSlide = getActiveSlide();
                if (!activeQuizSlide) return;
                const nextQuizSlide = getNextSlide();
                if (!nextQuizSlide) return;
                activeQuizSlide.classList.remove('active');
                nextQuizSlide.classList.add('active');
                checkIfAnswerIsSelected(nextQuizSlide);
                // button styling
                if (!nextQuizSlide.nextElementSibling) {
                    nextButton.classList.add('disabled');
                }
                if (prevButton.classList.contains('disabled')) {
                    prevButton.classList.remove('disabled');
                }
                // show repeat button on last result slide
                if (lastQuizSlide.classList.contains('active')) {
                    navigationButtons.classList.add('hidden');
                    repeatButtonDiv?.classList.remove('hidden');
                    repeatButtonDiv?.classList.add('flex');
                    repeatButton.classList.remove('hidden');
                    repeatButton.classList.add('flex');

                    // show result slide

                    // get all result slides
                    const resultSlides = quizSection.querySelectorAll(
                        '.last-quiz-slide'
                    ) as NodeListOf<HTMLElement>;
                    // loop over result slides
                    resultSlides.forEach((resultSlide) => {
                        // get score of result slide
                        const resultSlideScoreFrom = Number(
                            resultSlide.dataset.scoreFrom
                        );
                        const resultSlideScoreTo = Number(
                            resultSlide.dataset.scoreTo
                        );
                        // check if overall score is in range of result slide score
                        if (
                            overallScore >= resultSlideScoreFrom &&
                            overallScore <= resultSlideScoreTo
                        ) {
                            // show result slide
                            resultSlide.classList.add('active');
                            // hide all other result slides
                            resultSlides.forEach((slide) => {
                                if (slide !== resultSlide) {
                                    slide.classList.remove('active');
                                }
                            });

                            const lastActiveSlideHeight =
                                resultSlide.offsetHeight;
                            // set height of quiz content div to height of result slide (mobile)
                            quizContentDiv.style.height =
                                lastActiveSlideHeight + 'px';
                        }
                    });
                }
            });

            prevButton.addEventListener('click', function () {
                const activeQuizSlide = getActiveSlide();
                if (!activeQuizSlide) return;
                const prevQuizSlide = getPreviousSlide();
                if (!prevQuizSlide) return;
                checkIfAnswerIsSelected(prevQuizSlide);
                activeQuizSlide.classList.remove('active');
                prevQuizSlide.classList.add('active');
                // button styling
                if (!prevQuizSlide.previousElementSibling) {
                    prevButton.classList.add('disabled');
                }
                if (nextButton.classList.contains('disabled')) {
                    nextButton.classList.remove('disabled');
                }
            });

            repeatButton.addEventListener('click', function () {
                overallScore = 0;
                answerScore = 0;
                updateOverallScore(0);
                // hide repeat button
                repeatButton.classList.remove('flex');
                repeatButton.classList.add('hidden');
                // show navigation buttons
                if (navigationButtons.classList.contains('hidden')) {
                    navigationButtons.classList.remove('hidden');
                }
                // reset all slides to unactive
                quizSlides.forEach((quizSlide) => {
                    if (quizSlide.classList.contains('active')) {
                        quizSlide.classList.remove('active');
                    }
                });
                // set first slide active
                firstQuizSlide.classList.add('active');

                // update navigation buttons state
                prevButton.classList.add('disabled');

                const allAnswerOptions = quizSection.querySelectorAll(
                    '.answer-option'
                ) as NodeListOf<HTMLElement>;

                // remove all active class on all answer options
                allAnswerOptions.forEach((answer) => {
                    if (answer.classList.contains('active')) {
                        answer.classList.remove('active');
                    }
                });
                checkIfAnswerIsSelected(firstQuizSlide);
            });

            // durchklicken der slides
            quizSlides.forEach((slide) => {
                const answerOptions = slide.querySelectorAll(
                    '.answer-option'
                ) as NodeListOf<HTMLElement>;

                if (!answerOptions) return;
                // get score of answer option with click event listener
                answerOptions.forEach((answer) => {
                    answer.addEventListener('click', function () {
                        let oldSelectedScore = 0;
                        // remove all active class on all answer options
                        answerOptions.forEach((answerOption) => {
                            if (answerOption.classList.contains('active')) {
                                oldSelectedScore = Number(
                                    answerOption.dataset.score
                                );
                                answerOption.classList.remove('active');
                            }
                        });
                        // add active class on clicked option
                        answer.classList.add('active');
                        checkIfAnswerIsSelected(slide);
                        if (nextButton.classList.contains('disabled')) {
                            nextButton.classList.remove('disabled');
                        }
                        answerScore = Number(answer.dataset.score);
                        updateOverallScore(oldSelectedScore);
                    });
                });
            });

            function checkIfAnswerIsSelected(activeQuizSlide: HTMLElement) {
                const answerOptionsActiveSlide =
                    activeQuizSlide.querySelectorAll(
                        '.answer-option'
                    ) as NodeListOf<HTMLElement>;

                let answerSelected = false;
                answerOptionsActiveSlide.forEach((answer) => {
                    if (answer.classList.contains('active')) {
                        answerSelected = true;
                        if (answerSelected) {
                            if (nextButton?.classList.contains('disabled')) {
                                nextButton.classList.remove('disabled');
                            }
                        }
                    } else {
                        if (!answerSelected) {
                            nextButton?.classList.add('disabled');
                        }
                    }
                });
            }

            function adaptHeightOfSlide(
                quizSlideContentDivs: NodeListOf<HTMLElement>
            ) {
                let contentHeight = 0 as number;
                quizSlideContentDivs.forEach((div: HTMLElement) => {
                    const slideContentHeight = div.offsetHeight as number;
                    if (slideContentHeight > contentHeight) {
                        contentHeight = slideContentHeight;
                    }
                });
                quizSlideContentDivs.forEach((div: HTMLElement) => {
                    const parentDiv = div.parentElement;
                    if (parentDiv) {
                        parentDiv.style.height = contentHeight + 'px';
                    }
                });
                if (quizContentDiv) {
                    quizContentDiv.style.height = contentHeight + 'px';
                }
            }

            window.addEventListener('load', function () {
                checkIfAnswerIsSelected(firstQuizSlide);
                if (window.innerWidth < 768) {
                    adaptHeightOfSlide(quizSlideContentDivsMobile);
                } else {
                    adaptHeightOfSlide(quizSlideContentDivs);
                }
            });

            function updateOverallScore(oldSelectedScore: number) {
                overallScore = Number(
                    overallScore - oldSelectedScore + answerScore
                );
            }
        });
    }
};
