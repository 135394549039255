import {
    disableBodyScroll,
    clearAllBodyScrollLocks,
    enableBodyScroll
} from 'body-scroll-lock';
import anime from 'animejs';

export default {
    init() {
        //////////// Navigation
        const menu = document.getElementById('menu');
        const hamburger = document.getElementById('hamburger');
        const header = document.querySelector('.header-menu');
        const body = document.querySelector('body');
        const headerButton = document.querySelector('.btn-header-mobile');
        const sectionLink = document.querySelector('.section-link');
        const closeOverlay = document.querySelector('.header-overlay');

        if (!menu || !hamburger || !header || !body || !closeOverlay) {
            return;
        }

        if (headerButton) {
            headerButton.addEventListener('click', () => {
                if (menu.classList.contains('open')) {
                    menu.classList.remove('open');
                    hamburger.classList.remove('close');
                    header.classList.remove('open-menu');
                    body.classList.remove('overflow-hidden');
                    body.classList.remove('fixed');
                    enableBodyScroll(body);
                }
            });
        }

        if (sectionLink) {
            sectionLink.addEventListener('click', () => {
                if (menu.classList.contains('open')) {
                    menu.classList.remove('open');
                    hamburger.classList.remove('close');
                    header.classList.remove('open-menu');
                    body.classList.remove('overflow-hidden');
                    body.classList.remove('fixed');
                    enableBodyScroll(body);
                }
            });
        }

        // We listen to the resize event
        window.addEventListener('resize', () => {
            if (menu.classList.contains('open')) {
                menu.classList.remove('open');
                hamburger.classList.remove('close');
                header.classList.remove('open-menu');
                body.classList.remove('overflow-hidden');
                body.classList.remove('fixed');
                enableBodyScroll(body);
            }
        });

        if (hamburger) {
            hamburger.addEventListener('click', function () {
                const timeline = anime.timeline({
                    duration: 400,
                    easing: 'easeOutQuint'
                });

                // close menu
                if (menu.classList.contains('open')) {
                    menu.classList.remove('open');
                    hamburger.classList.remove('close');
                    header.classList.remove('open-menu');

                    timeline.add({
                        targets: '#menu',
                        opacity: 0,
                        complete: () => {
                            clearAllBodyScrollLocks();
                        }
                    });
                } else {
                    // open menu
                    disableBodyScroll(header);

                    menu.classList.add('open');
                    hamburger.classList.add('close');
                    header.classList.add('open-menu');

                    timeline.add({
                        targets: '#menu',
                        opacity: 1
                    });
                }
            });
        }

        const mainButtons = document.querySelectorAll(
            '.main-button'
        ) as NodeListOf<HTMLElement>;
        if (!mainButtons) {
            return;
        }
        // active first tab
        mainButtons.forEach(function (button: HTMLElement) {
            button.addEventListener('click', function () {
                // desktop menu
                const activeTargetIndex = button.dataset.targetIndex;
                const subbuttonDivs = document.querySelectorAll(
                    '.subbuttons'
                ) as NodeListOf<HTMLElement>;
                const activeMainButtonStyling =
                    button.querySelector('.menu-link-div');

                subbuttonDivs.forEach(function (subbuttonDiv: HTMLElement) {
                    const subbuttonsIndex = subbuttonDiv.dataset.contentIndex;
                    if (activeTargetIndex === subbuttonsIndex) {
                        // close subbuttons when clicking same button again
                        if (
                            subbuttonDiv.classList.contains(
                                'subbuttons-visible'
                            )
                        ) {
                            subbuttonDiv.classList.remove('subbuttons-visible');
                            subbuttonDiv.classList.add('not-visible');
                            closeOverlay.classList.add('hidden');
                            if (activeMainButtonStyling) {
                                activeMainButtonStyling.classList.remove(
                                    'active'
                                );
                            }
                        } else {
                            // open subbuttons
                            subbuttonDiv.classList.remove('not-visible');
                            subbuttonDiv.classList.add('subbuttons-visible');
                            closeOverlay.classList.remove('hidden');
                            if (activeMainButtonStyling) {
                                activeMainButtonStyling.classList.add('active');
                            }
                        }
                    }

                    // close other subbuttons
                    if (activeTargetIndex != subbuttonsIndex) {
                        subbuttonDiv.classList.add('not-visible');
                        subbuttonDiv.classList.remove('subbuttons-visible');
                        if (activeMainButtonStyling) {
                            activeMainButtonStyling.classList.remove('active');
                        }
                    }
                });
            });
        });

        // add scroll styling for header
        window.onscroll = function () {
            if (document.documentElement.scrollTop > header.clientHeight) {
                header.classList.add('bg-header-scroll');
            } else {
                header.classList.remove('bg-header-scroll');
            }
        };

        // close subbuttons menu when clicking outside of div
        closeOverlay.addEventListener('click', function () {
            const activeMenuItem = document.querySelector(
                '.subbuttons-visible'
            );
            if (activeMenuItem) {
                activeMenuItem.classList.remove('subbuttons-visible');
                activeMenuItem.classList.add('not-visible');
            }
            closeOverlay.classList.add('hidden');
        });
    }
};
