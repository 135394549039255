import { lock, unlock } from 'tua-body-scroll-lock';
import anime from 'animejs';

export default {
    init(container: HTMLElement) {
        const productCards = container.querySelectorAll(
            '.product-card'
        ) as NodeListOf<HTMLElement>;

        if (productCards) {
            productCards.forEach((card) => {
                const button = card.querySelector('.form-overlay-btn');
                const sectionId = card.dataset.sectionId;
                if (!button || !sectionId) return;

                const overlays = container.querySelectorAll(
                    '.form-overlay'
                ) as NodeListOf<HTMLElement>;

                if (overlays) {
                    overlays.forEach((overlay) => {
                        const overlayId = overlay.dataset.sectionId;

                        if (overlayId == sectionId) {
                            const content = overlay.querySelector(
                                '.content-container'
                            ) as HTMLElement;
                            const closeButton =
                                overlay.querySelector('.close-button');
                            const darkArea =
                                overlay.querySelector('.dark-area');

                            if (!content || !closeButton || !darkArea) return;

                            const appHeight = () => {
                                const doc = document.documentElement;
                                doc.style.setProperty(
                                    '--app-height',
                                    `${window.innerHeight}px`
                                );
                            };

                            // open Overlay
                            button.addEventListener('click', () => {
                                appHeight();
                                const timeline = anime.timeline();

                                timeline
                                    .add(
                                        {
                                            begin: () => {
                                                overlay.classList.remove(
                                                    'hidden'
                                                );
                                            },
                                            targets: overlay,
                                            opacity: [0, 1],
                                            easing: 'easeOutQuint',
                                            duration: 300
                                        },
                                        0
                                    )
                                    .add(
                                        {
                                            targets: content,
                                            translateX: ['100%', '0%'],
                                            duration: 300,
                                            easing: 'easeOutQuint',
                                            complete: () => {
                                                lock(content);
                                            }
                                        },
                                        400
                                    );
                            });

                            // close Overlay
                            darkArea.addEventListener('click', () => {
                                closeOverlay(overlay, content);
                            });

                            closeButton.addEventListener('click', () => {
                                closeOverlay(overlay, content);
                            });
                        }
                    });
                }
            });
        }

        /**
         * Close form overlay
         */
        function closeOverlay(overlay: HTMLElement, content: HTMLElement) {
            const closeTimeline = anime.timeline();

            closeTimeline
                .add(
                    {
                        targets: content,
                        translateX: ['0%', '100%'],
                        duration: 200,
                        easing: 'easeOutQuint'
                    },
                    0
                )
                .add(
                    {
                        targets: overlay,
                        opacity: [1, 0],
                        easing: 'easeOutQuint',
                        duration: 300,
                        complete: () => {
                            unlock(content);
                            overlay.classList.add('hidden');
                        }
                    },
                    300
                );
        }
    }
};
