import 'lazysizes';
import swiper from './ts/swiper';
import cardSlider from './ts/card-slider';
import 'intersection-observer';
import faq from './ts/faq';
import AOS from 'aos';
import 'aos/dist/aos.css';
import navigation from './ts/navigation';
import form from './ts/form';
import formQuiz from './ts/form-quiz';
import cookieBanner from './ts/cookie-banner';
import accordion from './ts/accordion';
import blogTeaser from './ts/blog-teaser';
import react from './react/index';
import formOverlayContainer from './ts/form-overlay';

// if you need react, just enable it by commenting in this line
// import react from './react/index';

import './app.scss';

(function () {
    // if you need react, just enable it by commenting in this line
    react.init();

    faq.init();

    navigation.init();

    AOS.init({ once: true });

    cardSlider.init();

    // formOverlayContainer
    const offeringsSections = document.querySelectorAll(
        '.offerings-section'
    ) as NodeListOf<HTMLElement>;
    if (offeringsSections.length) {
        offeringsSections.forEach((container) => {
            formOverlayContainer.init(container);
        });
    }

    // Init all swiper if there are some
    const allSwiper = document.querySelectorAll(
        '.swiper-container'
    ) as NodeListOf<HTMLElement>;
    if (allSwiper.length) {
        allSwiper.forEach(function (swiperElement) {
            if (!swiperElement.dataset.swiperOptions) return;
            // Set swiper element and swiper options from data-attribute
            swiper.init(
                swiperElement,
                JSON.parse(swiperElement.dataset.swiperOptions)
            );
        });
    }

    formQuiz.init();

    form.init();

    // remove comment if you want to add a cookie banner
    cookieBanner.init();

    accordion.init();

    blogTeaser.init();
})();
